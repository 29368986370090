@import 'src/styles/variables';
@import 'src/styles/mixins';

.summaryContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 12px;

  .summaryCard {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $deepBoxShadow;
    padding: 24px 16px;
    border: 1px solid $black10;
    border-radius: 12px;
    background-color: $white;

    .cardTitle {
      @include body-text('lg');
      line-height: 20px;
      margin-bottom: 16px;
      width: 130px;
    }

    .amountDetails {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .amount {
        font-weight: $boldFontWeight;
        line-height: 12px;
      }

      .amountLabel {
        text-transform: capitalize;
        @include body-text('md');
        color: $black60;
      }
    }
  }
}
